import React, { useState, useEffect } from 'react';
import Transaction from '../Table/Transaction/Transaction';
import Sum from '../Table/Sum/Sum';
import BankCard from '../Table/BankCard/BankCard';
import './merchant.css';


export default function Merchant({ data }) {

    return (
        <div className="merchant">
            <div className="merchantHeader">
                <h1>Статистика</h1>
            </div>
            {data && data.length !== 0 && (
                <table className="merchantDeals">
                    <tr className="merchantDealsHeader">
                        <th style={{ width: "30%" }}>Сделка</th>
                        <th style={{ width: "15%" }}>Сумма RUB</th>
                        <th style={{ width: "15%" }}>Сумма USDT</th>
                        <th style={{ width: "30%" }}>Карта</th>
                        <th style={{ width: "10%" }}>Банк</th>
                    </tr>
                    {data.payments.map((item) => (
                        <tr className="merchantDeal"
                            style={{ background: item.status === "AC" ? 'rgba(165, 224, 131, 0.501)' : item.status === "CL" ? 'rgba(255, 0, 0, 0.17)' : '#efefef' }}
                        >
                            <td className='firstBox'>
                                <Transaction
                                    key={item.payment_id}
                                    eventTime={item.last_update}
                                    icon={'iconoir:coins-swap'}
                                    style={{
                                        color: item.status === "AC" ? '#67c44e' : item.status === "CL" ? 'red' : 'grey',
                                        backgroundColor: item.status === "AC" ? 'rgba(165, 224, 131, 0.501)' : item.status === "CL" ? 'rgba(255, 0, 0, 0.17)' : '#efefef',

                                    }}
                                    text={`${item.deal_id} ${item.status === "AC"
                                        ? 'Завершенная сделка'
                                        : item.status == "CL"
                                            ? 'Платеж отклонен'
                                            : 'Платеж выполняется'}`}
                                />
                            </td>
                            <td>
                                <Sum
                                    key={`${item.payment_id}_sum`}
                                    transactionSum={item.amount}
                                    transactionSumInRub={item.amount}
                                />
                            </td>
                            <td>
                                <div className='sum'>
                                    <p className='sumUSDT'>{item.amount_usdt.toLocaleString('ru-RU', { maximumFractionDigits: 2 })} USDT</p>
                                </div>
                            </td>
                            <td>
                                <BankCard
                                    key={`${item.payment_id}_card`}
                                    bankCard={item.card_number}
                                    bank={item.type}
                                />
                            </td>
                            <td className='lastBox'>
                                <div className='sum'>
                                    <p className='sumUSDT'>{item.bank}</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
            )}
        </div>
    )
}