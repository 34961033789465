import React, { useState } from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js'
import { QRCodeSVG } from 'qrcode.react';
import Popup from 'reactjs-popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { add } from 'date-fns';

export default function Deposit({ balance, depositType, loading, payId }) {
  const [amount, setAmount] = useState('');
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [selectedInput, setSelectedInput] = useState('balance');
  const [address, setAddress] = useState('');

  if (payId < 10) {
    payId = '0' + payId;
  }

  const handleChange = (value) => {
    setSelectedInput(value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  }

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Проверка на целое число и диапазон от 1 и выше
    if (value >= 100 && value <= balance) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }
    setAmount(value);
  };

  function formatNumber(number) {
    return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
  }

  const handleCopy = () => {
    const textToCopy = 'TPXvgtCvy1ovnpwj9kYKasGC6FsFHYWwLB';

    const input = document.createElement('input');
    input.value = textToCopy;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    toast.success('Кошелек скопирован в буфер обмена!');
  };

  const handleWithdrawClick = async () => {
    if (amount && isAmountValid) {
      let body = {
        office_id: localStorage.getItem('office_token'),
        amount: amount,
        type: selectedInput
      };
      if (selectedInput === 'usdt') {
        body.address = address;
      }
      const responce = await fetch("/api/withdraw", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (responce.ok) {
        window.location.reload();
      }

      console.log('Деньги выведены')
    } else {
      console.log('Не получилось')
      setIsAmountValid(false)
    }
  }

  return (
    <Popup trigger={
      <div className='deposit' onClick={''}>
        <div className="balanceBlock">
          <p>{depositType ? 'Баланс депозита' : 'Баланс прибыли'}</p>
          {loading ? (
            <p className='balance'><Icon icon="line-md:downloading-loop" style={{ width: '24px', height: '24px', color: '#0088ff' }} /></p>
          ) : (<p className="balance">{formatNumber(balance)} ₽</p>)}

        </div>
        {depositType ?
          <button>
            <p>Пополнить</p><Icon icon="ei:plus" width="20px" height="20px" style={{ color: 'rgb(0, 136, 255)' }} />
          </button>
          :
          <button>
            <p>Вывести</p><Icon icon="ei:arrow-down" width="20px" height="20px" style={{ color: 'rgb(0, 136, 255)' }} />
          </button>
        }
      </div>
    } modal>
      {close => (depositType ?
        <div className="modal">
          <div className="modalHeader" style={{ flexDirection: 'column' }}>
            <h4>Пополнение баланса</h4>
            <p className='description'>Переведите нужную сумму в USDT на указанный кошелек или отсканируйте QR-код</p>
          </div>
          <div className="contentDeposit">
            <div className='inputData'>
              <label>Кошелек для пополнения</label>
              <div className="addressContainer">
                <div className='inputDipositCopy inputDiposit' onClick={handleCopy}>TPXvgtCvy1ovnpwj9kYKasGC6FsFHYWwLB</div>
                <Icon icon="mi:copy" className='copyIcon' onClick={handleCopy} width="24px" height="24px" />
              </div>
              <ToastContainer />
            </div>
            <div className='inputData'>
              <label>Сумма для пополнения (обязательно указывайте центы - это идентификатор офиса, как в примере)</label>
              <div className="addressContainer">
                <div className='inputDiposit' style={{ cursor: 'default' }}>1000.{payId}</div>
              </div>
            </div>
            <div className='inputData'>
              <label>QR-код для пополнения</label>
              <div className="addressContainerQr">
                <QRCodeSVG value="TPXvgtCvy1ovnpwj9kYKasGC6FsFHYWwLB" />
              </div>
            </div>
            <div className="warning">
              <Icon icon="mingcute:warning-line" width="24px" height="24px" style={{ color: '#707070' }} />
              <div className="warningText">
                <p>Данный адрес предназначен только для переводов Tether в сети TRC20.</p>
                <p>
                  При пополнении депозита с некорректным идентификатором площадка<br></br> не несет финансовой ответственности за депозит.<br></br>
                  Настоятельно рекомендуем внимательно проверять идентификатор перед отправкой депозита на площадку.
                </p>
              </div>
            </div>
          </div>
          <div className="modalActions">
            <button className="closeModal" onClick={close}>Отмена</button>
            <button className="addReqBtn" onClick={close}>Понятно</button>
          </div>
        </div>
        :
        <div className="modal">
          <div className="modalHeader" style={{ flexDirection: 'column' }}>
            <h4>Вывод средств</h4>
            <p className='description'>Перевод средств с баланса прибыли</p>
          </div>
          <div className="content">
            <div className="profitBalance">
              <p>Баланс прибыли</p>
              <p className="balance">{formatNumber(balance)} ₽</p>
            </div>
            <form className='profitForm'>
              <div
                className={`profitInput ${selectedInput === 'balance' ? 'selected' : ''}`}
                onClick={() => handleChange('deposit')}
              >
                <label htmlFor="balance">На баланс депозита</label>
                <input type="radio" id="balance" name="balance" value="balance" checked={selectedInput === 'balance'} onChange={() => handleChange('balance')} />
              </div>
              <div
                className={`profitInput ${selectedInput === 'usdt' ? 'selected' : ''}`}
                onClick={() => handleChange('usdt')}
              >
                <label htmlFor="usdt">В USDT TRC20</label>
                <input type="radio" id="usdt" name="currency" value="usdt" checked={selectedInput === 'usdt'} onChange={() => handleChange('usdt')} />
              </div>
              <div className='checkSum'>
                <label htmlFor="sum">Сумма</label>
                <div className="checkInput">
                  <input
                    id="sum"
                    type="text"
                    placeholder="Укажите сумму"
                    value={amount}
                    onChange={handleAmountChange}
                    onFocus={() => setIsAmountValid(true)}
                    style={{ width: '100%' }}
                  />
                  <div className="selectCurrency">
                    <Icon icon="cryptocurrency-color:rub" width="18px" height="18px" />
                    <p>RUB</p>
                  </div>
                </div>
              </div>

              {selectedInput === 'usdt' ? (
                <div className="addressInput">
                  <label htmlFor="address">Адрес</label>
                  <input
                    id='address'
                    type="text"
                    placeholder="Укажите адрес"
                    value={address}
                    onChange={handleAddressChange}
                  />
                </div>
              ) : (<></>)}

              {!isAmountValid && (
                <div style={{ color: 'red', fontSize: '10px' }}>
                  Не менее 100 RUB и не более Баланса прибыли.
                </div>
              )}
              <div>
              </div>
            </form>
          </div>
          <div className="modalActions">
            <button className="closeModal" onClick={close}>Отмена</button>
            <button className="addReqBtn" onClick={handleWithdrawClick}>Вывести</button>
          </div>
        </div>
      )}
    </Popup>
  )
}
