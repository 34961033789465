import React from "react";
import "./module.css"


export default function OpenTabFilter({ index, handleClick, search, setSearch }) {
    return (
        <div className="linksContainer">
            <div className="openTabFilterContainer">
                <button
                    className={`switchState ssdeals ${index === 0 ? 'active' : ''}`}
                    onClick={() => handleClick(0)}
                >
                    Сделки
                </button>
                <button
                    className={`switchState ssdispute ${index === 1 ? 'active' : ''}`}
                    onClick={() => handleClick(1)}
                >
                    Споры
                </button>
                <button
                    className={`switchState ssmessages ${index === 2 ? 'active' : ''}`}
                    onClick={() => handleClick(2)}
                >
                    Сообщения
                </button>
            </div>
            {index === 2 && (
                <div className="searchMessages">
                    <input 
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Поиск в тексте сообщения"
                    />
                </div>
            )}
        </div>
    );
}