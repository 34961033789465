import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Sidebar from '../Dashboard/Sidebar/Sidebar';
import './App.css';
import data from '../Data';
import Dashboard from '../Dashboard/Dashboard';
import Finance from '../Dashboard/pages/Finance/Finance';
import Dispute from '../Dashboard/pages/Dispute/Dispute';
import OpenDispute from '../Dashboard/pages/Dispute/OpenDispute/OpenDispute';
import OpenDeals from '../Dashboard/pages/Deals/OpenDealsGeneral/OpenDealsGeneral';
import OpenRequisite from '../Dashboard/pages/Requisite/OpenRequisiteGeneral/OpenRequisiteGeneral';
import OpenDevice from '../Dashboard/pages/Device/OpenDeviceGeneral/OpenDeviceGeneral';
import Deals from '../Dashboard/pages/Deals/Deals';
import Requisite from '../Dashboard/pages/Requisite/Requisite';
import Device from '../Dashboard/pages/Device/Device';
import Login from '../components/Login/Login';
import LoginMerchant from '../components/LoginMerchant/Login';
import SidebarMerchant from '../components/SidebarMerchant/SidebarMerchant';
import Merchant from '../components/Merchant/Merchant';
import Receipt from '../components/Receipt/Receipt';
import Stat from '../Dashboard/pages/Stats/Stat';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingState, setLoadingState] = useState({
    dashboard: false,
    finance: false,
    deals: false,
    dispute: false,
    openDispute: false,
    requisite: false,
    device: false,
    stat: false,
  });

  const [dataMerchant, setDataMerchant] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const ip = await getIPAddress();

      try {
        const response = await fetch(`/api/session?ip=${ip}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          if (data.login) {
            setIsAuthenticated(true);
            if (window.location.pathname === "/login") {
              navigate('/');
            }
            console.log(window.location.pathname);
          } else {
            setIsAuthenticated(false);
            // localStorage.removeItem('office_token');
            // localStorage.removeItem('office_type');
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Ошибка при проверки активной сессии', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    // const officeType = localStorage.getItem('office_type');
    // console.log(officeType);
    // if (officeType !== 'merchant' || !officeType) {
    //   checkAuth();
    // } else {
    //   fetchDataMerchant();
    // }
    checkAuth();
  }, []);

  useEffect(() => {
    const officeType = localStorage.getItem('office_type');
    if (officeType == 'merchant') {
      fetchDataMerchant();
    }
  }, [selectedDate])

  const fetchDataMerchant = async () => {
    const officeToken = localStorage.getItem('office_token');
    try {
      const url = '/api/v1/merch/data?merchant_id=' + officeToken + '&date=' + selectedDate;
      const response = await fetch(url);
      if (response.status === 200) {
        const data = await response.json();
        setDataMerchant(data);
        setLoading(false);
        navigate('/merchant');
      } else {
        navigate('/login/merchant');
      }
    } catch {
      setLoading(false);
      navigate('/login/merchant');
    }
  }

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const setPageLoading = (page, isLoading) => {
    setLoadingState((prev) => ({
      ...prev,
      [page]: isLoading,
    }));
  };

  const getIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Ошибка при получении IP-адреса:', error);
      return '0.0.0.0';
    }
  };

  if (loading) {
    return <div style={{ display: 'none' }}>Loading...</div>;
  } else {
    return (
      <Routes>
        <Route path='/login' element={<Login onLogin={handleLogin} />} />
        <Route path='/login/merchant' element={<LoginMerchant onLogin={handleLogin} />} />
        <Route path='/merchant' element={<SidebarMerchant data={dataMerchant} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />}>
          <Route index element={<Merchant data={dataMerchant} />} />
        </Route>
        {isAuthenticated ? (
          <>
            <Route path='/try' element={<Dashboard data={data} setPageLoading={(isLoading) => setPageLoading('dashboard', isLoading)} />} />
            <Route path='/' element={<Sidebar data={data} loadingState={loadingState} setPageLoading={setPageLoading} />} >
              <Route index element={<Dashboard data={data} setPageLoading={(isLoading) => setPageLoading('dashboard', isLoading)} />} />
              <Route path='finance' element={<Finance data={data} setPageLoading={(isLoading) => setPageLoading('finance', isLoading)} />} />
              <Route path='deals' element={<Deals data={data} setPageLoading={(isLoading) => setPageLoading('deals', isLoading)} />} />
              <Route path='dispute' element={<Dispute data={data} setPageLoading={(isLoading) => setPageLoading('dispute', isLoading)} />} />
              <Route path='dispute/:id' element={<OpenDispute data={data} setPageLoading={(isLoading) => setPageLoading('dispute', isLoading)} />} />
              <Route path='deals/:id' element={<OpenDeals data={data} setPageLoading={(isLoading) => setPageLoading('deals', isLoading)} />} />
              <Route path='requisite/:id' element={<OpenRequisite data={data} setPageLoading={(isLoading) => setPageLoading('requisite', isLoading)} />} />
              <Route path='device/:id' element={<OpenDevice data={data} setPageLoading={(isLoading) => setPageLoading('device', isLoading)} />} />
              <Route path='requisite' element={<Requisite data={data} setPageLoading={(isLoading) => setPageLoading('requisite', isLoading)} />} />
              <Route path='device' element={<Device data={data} setPageLoading={(isLoading) => setPageLoading('device', isLoading)} />} />
              <Route path='stat' element={<Stat setPageLoading={(isLoading) => setPageLoading('stat', isLoading)} />} />
              <Route path='profile' element={<Dashboard />} />
              <Route path='receipt' element={<Receipt />} />
            </Route>
          </>

        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    );
  }
}

export default App;
