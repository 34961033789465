import React, { useState, useEffect } from 'react';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import Table from '../../../components/Table/Table';
import Verification from '../../../components/ModalVerification/Verification';
import { Icon } from '@iconify/react/dist/iconify.js';
import './module.css';
import Popup from 'reactjs-popup';

import { generateRows } from '../../../Data';

function AddRequisite({ create, setCreate }) {
    const [fullName, setFullName] = useState('');
    const [surname, setSurname] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [amount, setAmount] = useState(null);
    const [verif, setVerif] = useState(false)
    const [devices, setDevices] = useState('')
    const [device, setDevice] = useState(null)
    const [dayLimit, setDayLimit] = useState(0)
    const [errors, setErrors] = useState({});
    const [card, setCard] = useState({
        cardId: '',
        cardNumber: '',
        phone: '',
        paymentNumber: '',
        bank: '',
    });
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    // Update Добавление доп счетов
    const [dopPayments, setDopPayments] = useState(0);
    const [dop, setDop] = useState({});
    const [dopError, setDopError] = useState({});

    const handleAddDop = () => {
        setDop(prevDop => ({
            ...prevDop,
            [dopPayments]: '',
        }))
        setDopPayments(dopPayments + 1);
    }

    const handleDeleteDop = () => {
        setDop(prevDop => {
            const newDop = { ...prevDop };
            delete newDop[dopPayments - 1];
            return newDop;
        });
        setDopPayments(dopPayments - 1);
    }

    const handleDopChange = (index, value) => {
        let formattedValue = value;
        formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        setDop(prevDop => ({
            ...prevDop,
            [index]: formattedValue,
        }))
    }

    const validateDopPayments = () => {
        Array.from({ length: dopPayments }, (_, index) => {
            const cleanedNumber = dop[index].replace(/\s/g, '');
            if (cleanedNumber.length === 20 && /^\d+$/.test(cleanedNumber)) {
                console.log("dop true");
            } else {
                console.log("dop false");
                setDopError(prevError => ({
                    ...prevError,
                    [index]: "Введите корректный номер счета",
                }));
            }
        });
    }
    // *

    // Update Добавление доп карт
    const [dopCards, setDopCards] = useState(0);
    const [dopCard, setDopCard] = useState({});
    const [dopCardError, setDopCardError] = useState({});

    const handleAddDopCard = () => {
        setDopCard(prevDop => ({
            ...prevDop,
            [dopCards]: '',
        }))
        setDopCards(dopCards + 1);
    }

    const handleDeleteDopCard = () => {
        setDopCard(prevDop => {
            const newDop = { ...prevDop };
            delete newDop[dopCards - 1];
            return newDop;
        });
        setDopCards(dopCards - 1);
    }

    const handleDopCardChange = (index, value) => {
        let formattedValue = value;
        formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        setDopCard(prevDop => ({
            ...prevDop,
            [index]: formattedValue,
        }))
    }

    const validateDopCard = () => {
        Array.from({ length: dopCards }, (_, index) => {
            const cleanedNumber = dopCard[index].replace(/\s/g, '');
            if (cleanedNumber.length === 16 && /^\d+$/.test(cleanedNumber)) {
                console.log("dop true");
            } else {
                console.log("dop false");
                setDopCardError(prevError => ({
                    ...prevError,
                    [index]: "Введите корректный номер карты",
                }));
            }
        });
    }

    // *

    const fetchData = async () => {
        try {
            if (!card) {
                throw new Error('Invalid card data');
            }
            const office_id = localStorage.getItem('office_token');
            const response = await fetch('/api/detail/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    full_name: surname + ' ' + fullName + ' ' + middleName,
                    card_number: card.cardNumber,
                    phone_number: card.phone,
                    payment_number: card.paymentNumber,
                    office_id: office_id,
                    device_id: device.token,
                    bank: selectedBank.id,
                    card_mode: 'sbp',
                    day_limit: dayLimit.replace(/\D/g, ''),
                    additional: {
                        payment: dop,
                        card: dopCard,
                    }
                })
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setCreate(data.create)
                setAmount(data.verif_amount)
                const responseCard = await fetch(`/api/detail-full/${data.card_id}`);
                if (responseCard.ok) {
                    const dataCard = await responseCard.json();
                    setCard(dataCard.card);
                }
                else {
                    setCard({
                        cardId: data.cardId,
                        cardNumber: card.cardNumber,
                        phone: card.phone,
                        lastFourDigits: card.lastFourDigits,
                        paymentLink: card.paymentLink
                    })
                }
            } else {
                console.error({ error: 'Ошибка при добавлении реквизита' });
                setErrors('Ошибка при добавлении реквизита');
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const office_id = localStorage.getItem('office_token');
                const response = await fetch(`/api/devices?office_id=${office_id}`)
                if (response.ok) {
                    const data = await response.json();
                    setDevices(data)

                } else {
                    console.log('Не прошло')
                }
            } catch (error) {
                console.log('Ошибочка')
            }
        }

        const fetchBanks = async () => {
            try {
                const response = await fetch('/api/banks')
                if (response.ok) {
                    const data = await response.json();
                    setBanks(data);
                } else {
                    console.log('Не прошло')
                }
            } catch (error) {
                console.log('Ошибочка')
            }
        }

        fetchDevices();
        fetchBanks();
    }, [])

    const handleClose = (close) => {
        setCard({
            cardId: '',
            cardNumber: '',
            phone: '',
            lastFourDigits: '',
            paymentLink: ''
        });
        close();
    }

    const handleCardChange = (field, value) => {

        let formattedValue = value;

        // Применяем форматирование в зависимости от поля
        if (field === 'cardNumber') {
            formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim(); // Форматирование для номера карты
        } else if (field == 'paymentNumber') {
            formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        } else if (field === 'phone') {
            formattedValue = value.replace(/\D/g, ''); // Убираем нецифровые символы

            function ruNumber(value) {
                formattedValue = value
                if (formattedValue.length > 11) formattedValue = formattedValue.slice(0, 11);
                let phoneFormatted = '+';
                if (formattedValue.length > 0) phoneFormatted += formattedValue.slice(0, 1);
                if (formattedValue.length > 1) phoneFormatted += ' ' + formattedValue.slice(1, 4);
                if (formattedValue.length > 4) phoneFormatted += ' ' + formattedValue.slice(4, 7);
                if (formattedValue.length > 7) phoneFormatted += ' ' + formattedValue.slice(7, 9);
                if (formattedValue.length > 9) phoneFormatted += ' ' + formattedValue.slice(9, 11);
                return phoneFormatted.trim();
            }

            function tjNumber(value) {
                formattedValue = value
                if (formattedValue.length > 15) formattedValue = formattedValue.slice(0, 15);
                let phoneFormatted = '+';
                if (formattedValue.length > 0) phoneFormatted += formattedValue.slice(0, 1);
                if (formattedValue.length > 1) phoneFormatted += formattedValue.slice(1, 3) + ' ';
                if (formattedValue.length > 3) phoneFormatted += formattedValue.slice(3, 5) + ' ';
                if (formattedValue.length > 5) phoneFormatted += formattedValue.slice(5, 8) + ' ';
                if (formattedValue.length > 8) phoneFormatted += formattedValue.slice(8, 10) + ' ';
                if (formattedValue.length > 10) phoneFormatted += formattedValue.slice(10, 12) + ' ';
                if (formattedValue.length > 12) phoneFormatted += formattedValue.slice(12, 15);

                return phoneFormatted.trim();
            }
            
            console.log(formattedValue[0]);
            if (formattedValue[0] === "9") {
                console.log("tj");
                formattedValue = tjNumber(formattedValue);
            } else {
                formattedValue = ruNumber(formattedValue);
            }
            
        }
        
        setCard(prevCard => ({
            ...prevCard,
            [field]: formattedValue
        }));
    };

    const validateCardNumber = (number, length) => {
        const cleanedNumber = number.replace(/\s/g, '');
        return cleanedNumber.length === length && /^\d+$/.test(cleanedNumber);
    };

    const handleSubmit = () => {
        const validationErrors = {};

        if (!surname) validationErrors.surname = 'Введите фамилию';
        if (!fullName) validationErrors.fullName = 'Введите имя';
        if (!middleName) validationErrors.middleName = 'Введите отчество';
        if (!validateCardNumber(card.cardNumber, 16) && selectedBank.card_number) validationErrors.cardNumber = 'Введите корректный номер карты';
        if (!card.phone && selectedBank.phone_number) validationErrors.phone = 'Введите номер телефона';
        if (!validateCardNumber(card.paymentNumber, 20) && selectedBank.payment_number) validationErrors.paymentNumber = 'Введите корректный номер счета';
        // if (!/^https?:\/\/.+/.test(card.paymentLink) || !card.paymentLink === '') validationErrors.paymentLink = 'Введите корректную ссылку на оплату';

        if (dopPayments > 0) {
            validateDopPayments();
            if (Object.keys(dopError).length !== 0) {
                return
            }
        }
        if (dopCards > 0) {
            validateDopCard();
            if (Object.keys(dopCardError).length !== 0) {
                return
            }
        }


        if (Object.keys(validationErrors).length === 0) {
            fetchData();
        } else {
            setErrors(validationErrors);
        }
    };


    const handleLimitChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');

        const formattedValue = parseInt(value, 10).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
        });
    
        setDayLimit(formattedValue);
    };

    const handleDeviceChange = (e) => {
        const deviceToken = e.target.value;
        const selected = devices.find(device => device.token === deviceToken);
        setDevice(selected);
    };

    const handleBankChange = (e) => {
        const bankId = parseInt(e.target.value);
        const selected = banks.find(bank => bank.id === bankId);
        setSelectedBank(selected);
        setCard(prevCard => ({
            ...prevCard,
            bank: selected.name
        }));
    }

    return (
        <Popup trigger={
            <button className="addRequisite">
                <Icon icon="gravity-ui:plus" width="20px" height="20px" style={{ color: 'white' }} />
                <p>Добавить реквизиты</p>
            </button>
        } modal>
            {close => (
                !create ?
                    <div className="modal">
                        <div className="modalHeader">Добавление новых реквизитов</div>
                        <div className="content">
                            <div className="inputData">
                                <label htmlFor="device" className='label'>Устройство</label>
                                <select name="device" id="device" className="select" defaultValue="" onChange={handleDeviceChange}>
                                    <option value="" disabled>Выберите устройство</option>
                                    {devices.length !== 0 && devices.map(device => (
                                        <option key={device.token} value={device.token}>
                                            {device.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="inputData">
                                <label htmlFor="bank" className='label'>Банк</label>
                                <select name='bank' id='bank' className='select' defaultValue="" onChange={handleBankChange}>
                                    <option value="" disabled>Выберите банк</option>
                                    {banks.length > 0 && banks.map(bank => (
                                        <option key={bank.id} value={bank.id}>
                                            {bank.name_full}
                                        </option>
                                    ))}
                                </select>
                                {/* <div className="bankOverview">Сбербанк</div> */}
                            </div>
                            <div className="inputData">
                                <label htmlFor="limit" className="label">Суточный лимит</label>
                                <div className="dayLimitInput">
                                    <input
                                        type="text"
                                        id='dayLimit'
                                        placeholder='Введите суточный лимит'
                                        onChange={handleLimitChange}
                                        value={dayLimit}
                                        maxLength={11}
                                    />
                                </div>
                            </div>
                            <div className="fullNameOfRequisite">
                                <div className='inputData'>
                                    <label>Фамилия</label>
                                    <input
                                        type="text"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        onFocus={() => setErrors({})}
                                        placeholder="Фамилия"
                                    />
                                    {errors.surname && <span style={{ color: 'red' }}>{errors.surname}</span>}
                                </div>
                                <div className='inputData'>
                                    <label>Имя</label>
                                    <input
                                        type="text"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        onFocus={() => setErrors({})}
                                        placeholder="Имя"
                                    />
                                    {errors.fullName && <span style={{ color: 'red' }}>{errors.fullName}</span>}
                                </div>
                                <div className='inputData'>
                                    <label>Отчество</label>
                                    <input
                                        type="text"
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        onFocus={() => setErrors({})}
                                        placeholder="Отчество"
                                    />
                                    {errors.middleName && <span style={{ color: 'red' }}>{errors.middleName}</span>}
                                </div>
                            </div>

                            {selectedBank?.id && (
                                <div>
                                    {selectedBank.card_number && (
                                        <div className='inputData'>
                                            <label>Основная карта</label>
                                            <div className="inputDataCard">
                                                <Icon icon='ion:card-outline' width="20px" height="20px" />
                                                <input
                                                    type="text"
                                                    value={card.cardNumber}
                                                    onChange={(e) => handleCardChange('cardNumber', e.target.value)}
                                                    onFocus={() => setErrors({})}
                                                    placeholder="Введите карту"
                                                    maxLength="19"
                                                />
                                            </div>
                                            {errors.cardNumber && <span style={{ color: 'red' }}>{errors.cardNumber}</span>}
                                        </div>
                                    )}
                                    {selectedBank.payment_number && !selectedBank.card_number && (
                                        <div className='inputData'>
                                            <label>Основной счет</label>
                                            <div className="inputDataCard">
                                                <Icon icon='ion:card-outline' width="20px" height="20px" />
                                                <input
                                                    type="text"
                                                    value={card.paymentNumber}
                                                    onChange={(e) => handleCardChange('paymentNumber', e.target.value)}
                                                    onFocus={() => setErrors({})}
                                                    placeholder="Введите номер счета"
                                                    maxLength="24"
                                                />
                                            </div>
                                            {errors.paymentNumber && <span style={{ color: 'red' }}>{errors.paymentNumber}</span>}
                                        </div>
                                    )}
                                    <div className="cardContacts">
                                        {selectedBank.phone_number && (
                                            <div className='inputData'>
                                                <label>Телефон</label>
                                                <div className="inputDataCard">
                                                    <Icon icon="ph:sim-card-light" width="24px" height="24px" />
                                                    <input
                                                        type="text"
                                                        value={card.phone}
                                                        onChange={(e) => handleCardChange('phone', e.target.value)}
                                                        onFocus={() => setErrors({})}
                                                        placeholder="Введите номер"
                                                        maxLength="18"
                                                    />
                                                </div>
                                                {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {dopPayments > 0 && (
                                <div className='dopReq'>
                                    <label>Дополнительные счета</label>
                                    {Array.from({ length: dopPayments }, (_, index) => (
                                        <>
                                            <div className='dopReqFlex'>
                                                <div className="inputDataCard dop">
                                                    <Icon icon='ion:card-outline' width="20px" height="20px" />
                                                    <input
                                                        type="text"
                                                        value={dop[index]}
                                                        onChange={(e) => handleDopChange(index, e.target.value)}
                                                        onFocus={() => { setErrors({}); setDopError({}) }}
                                                        placeholder="Введите дополнительный счет"
                                                        maxLength="24"
                                                    />
                                                </div>
                                                <button className="icon-button" onClick={handleDeleteDop}>
                                                    <Icon icon="wpf:delete" className='deleteIcon' width="20px" height="20px" />
                                                </button>

                                            </div>
                                            {dopError[index] && <span style={{ color: 'red' }}>{dopError[index]}</span>}
                                        </>
                                    ))}
                                </div>
                            )}
                            {(selectedBank.additional_1 === 'payment_number'
                                || selectedBank.additional_2 === 'payment_number'
                                || selectedBank.additional_2 === 'payment_number') && (
                                    <button className='addCard' onClick={handleAddDop}>
                                        <Icon className='plusCard' icon="gravity-ui:plus" width="20px" height="20px" />
                                        <p>Добавить дополнительный счет</p>
                                    </button>
                                )}
                            {dopCards > 0 && (
                                <div className='dopReq'>
                                    <label>Дополнительные карты</label>
                                    {Array.from({ length: dopCards }, (_, index) => (
                                        <>
                                            <div className='dopReqFlex'>
                                                <div className="inputDataCard dop">
                                                    <Icon icon='ion:card-outline' width="20px" height="20px" />
                                                    <input
                                                        type="text"
                                                        value={dopCard[index]}
                                                        onChange={(e) => handleDopCardChange(index, e.target.value)}
                                                        onFocus={() => { setErrors({}); setDopCardError({}) }}
                                                        placeholder="Введите дополнительную карту"
                                                        maxLength="19"
                                                    />
                                                </div>
                                                <button className="icon-button" onClick={handleDeleteDopCard}>
                                                    <Icon icon="wpf:delete" className='deleteIcon' width="20px" height="20px" />
                                                </button>

                                            </div>
                                            {dopCardError[index] && <span style={{ color: 'red' }}>{dopCardError[index]}</span>}
                                        </>
                                    ))}
                                </div>
                            )}
                            {(selectedBank.additional_1 === 'card_number'
                                || selectedBank.additional_2 === 'card_number'
                                || selectedBank.additional_2 === 'card_number') && (
                                    <button className='addCard' onClick={handleAddDopCard}>
                                        <Icon className='plusCard' icon="gravity-ui:plus" width="20px" height="20px" />
                                        <p>Добавить дополнительную карту</p>
                                    </button>
                                )}
                        </div>
                        <div className="modalActions">
                            <button className="closeModal" onClick={() => handleClose(close)}>Отмена</button>
                            <button className="addReqBtn" onClick={handleSubmit}>Добавить</button>
                        </div>
                    </div>
                    : create && !verif &&
                    <Verification card={card} amount={amount} close={close} setVerif={setVerif} dop={false} />
            )
            }
        </Popup >
    );
}


function StateActivity({ index, handleClick }) {

    return (
        <div className="stateBar">
            <button
                className={`switchState ssall ${index === 0 ? 'active' : ''}`}
                onClick={() => handleClick(0)}
            >
                Все
            </button>
            <button
                className={`switchState ssactive ${index === 1 ? 'active' : ''}`}
                onClick={() => handleClick(1)}
            >
                Активно
            </button>
            <button
                className={`switchState ssnonconnected ${index === 2 ? 'active' : ''}`}
                onClick={() => handleClick(2)}
            >
                Не подключено
            </button>
            <button
                className={`switchState ssnonactive ${index === 3 ? 'active' : ''}`}
                onClick={() => handleClick(3)}
            >
                Приостановлено
            </button>
        </div>
    )
}

export default function Requisite({ data, setPageLoading }) {
    const [create, setCreate] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [filterStatus, setFilterStatus] = useState('');
    const [deleteCard, setDeleteCard] = useState(false);
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'requisite');

    useEffect(() => {
        const fetchData = async () => {

            setPageLoading(true);
            try {
                let url = `/api/details?office_id=${officeId}`;
                if (filterStatus) {
                    url += `&status=${filterStatus}`;
                }
                // if (searchData) {
                //     url = `/api/payment/${searchData}`;
                // }


                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    // console.log(dynamicData);

                    let formattedData = [];

                    if (Array.isArray(dynamicData)) {
                        formattedData = dynamicData.map(item => ({
                            transactionId: item.card.id,
                            transactionOwnerName: item.card.name,
                            transactionNickName: item.device.name,
                            transactionStatus: item.card.condition ? 'Активно' : item.card.verif ? 'Приостановлено' : 'Не подключено',
                            transactionBalance: item.card.balance,
                            transactionBankCard: item.card.bank === 'sber' ? item.card.payment_number : item.card.number,
                            transactionBankName: item.card.bank_full,
                            transactionCurrentSum: item.card.day_volume,
                            transactionLimit: item.card.day_limit,
                            transactionSuccessfullDeals: item.card.deals_approve,
                            transactionAllDeals: item.card.deals,
                            transactionSetDelete: setDeleteCard,
                        }));
                    } else {
                        console.error('Неверный формат данных:', dynamicData);
                    }

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }

            } catch (error) {
                console.error('Ошибка:', error);

            } finally {
                setPageLoading(false);
                setDeleteCard(false);
            }
        };
        fetchData();
    }, [filterStatus, data, create, deleteCard])

    // Для получения данных, исправить на норм

    const handleClick = (index) => {
        setActiveIndex(index);
        switch (index) {
            case 0: // Все
                setFilterStatus('');
                break;
            case 1: // Активно
                setFilterStatus('active');
                break;
            case 2: // Не подключено
                setFilterStatus('no-verif');
                break;
            case 3: // Не активно
                setFilterStatus('non-active');
                break;
            default:
                setFilterStatus('');
        }
    };


    const searchByCard = async (card) => {
        const response = await fetch(`/api/details?office_id=${officeId}&card=${card}`);
        if (response.ok) {
            const data = await response.json();
            if (!data?.card) {
                setFilteredData([]);
            } else {
                let formattedData = [{
                    transactionId: data.card.id,
                    transactionOwnerName: data.card.name,
                    transactionNickName: data.device.name,
                    transactionStatus: data.card.condition ? 'Активно' : data.card.verif ? 'Приостановлено' : 'Не подключено',
                    transactionBalance: data.card.balance,
                    transactionBankCard: data.card.number,
                    transactionBankName: data.card.bank,
                    transactionCurrentSum: data.card.day_volume,
                    transactionLimit: data.card.day_limit,
                    transactionSuccessfullDeals: data.card.deals_approve,
                    transactionAllDeals: data.card.deals_percent,
                    transactionSetDelete: setDeleteCard,
                }]
                setFilteredData(formattedData);
            }
        }
        setDeleteCard(false);
    }

    return (
        <div className='requisite'>
            <div className="header">
                <h1>Реквизиты</h1>
                <AddRequisite create={create} setCreate={setCreate} />
            </div>
            <div className='headerActions'>
                <StateActivity index={activeIndex} handleClick={handleClick} />
                <SearchFilter data={data.tables.requisite.searchInput} onFilterChange={searchByCard} />
            </div>
            <Table
                headers={data.tables.requisite.headers}
                rows={genRows.map(row => row.components)}
                rowHover={'rowHover'}
            />
        </div>
    );
}