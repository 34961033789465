import React, { useEffect } from 'react';
import './module.css';
import Transaction from '../../../../components/Table/Transaction/Transaction';
import Sum from '../../../../components/Table/Sum/Sum';
import BankCard from '../../../../components/Table/BankCard/BankCard';
import DeviceComponent from '../../../../components/Table/DeviceComponent/DeviceComponent';

export default function OpenDeals({ props }) {
    // Деструктурируем данные из props

    // useEffect(() => {
    //     console.log(props);
    // }, [props]);

    return (
        <div className='openDealsContainer'>
            <p className='OpenDealsHeaderName'>Сделка</p>
            <div className='headerNameDeals'>
                <div className='dealsHeader'>Сделка</div>
                <div className='sumHeader'>Сумма</div>
                <div className='cardHeader'>Карта</div>
                <div className='deviceHeader'>Устройство</div>
            </div>
            {props && props.dispute && props.payment && props.card && props.device && (
                <div className='dealRow'>
                    <div className='dealData'>
                        <Transaction
                            eventTime={props?.payment?.date?.create}
                            icon={'iconoir:coins-swap'}
                            style={{
                                color: props?.payment?.payment?.status === "AC" ? '#67c44e' : props?.payment?.payment?.status === "CL" ? 'red' : 'grey',
                                backgroundColor: props?.payment?.payment?.status === "AC" ? 'rgba(165, 224, 131, 0.501)' : props?.payment?.payment?.status === "CL" ? 'rgba(255, 0, 0, 0.17)' : '#efefef'
                            }}
                            text={props?.payment?.payment?.status === "AC" ? 'Завершенная сделка' : props?.payment?.payment?.status === "CL" ? 'Платеж отклонен' : 'Платеж выполняется'}
                        />
                    </div>
                    <div className='sumData'>
                        <Sum
                            transactionSum={props?.payment?.payment?.amount?.usdt}
                            transactionSumInRub={props?.payment?.payment?.amount?.rub}
                        />
                    </div>
                    <div className='cardData'>
                        <BankCard
                            bankCard={props?.payment?.payment?.card?.number}
                            bank={props?.card?.bank}
                        />
                    </div>
                    <div className='deviceData'>
                        <div>
                            <DeviceComponent
                                name={props?.device?.name}
                                uniqDevice={props?.device?.token}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}