import './stat.css'
import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';

export default function Stat({ setPageLoading }) {

    const [data, setData] = useState({});
    const [search, setSearch] = useState("");

    useEffect(() => {
        const fetchStat = async () => {
            setPageLoading(true);
            let url = '';
            if (search === "") {
                url = '/api/stat';
            } else {
                url = '/api/stat?office_id=' + search;
            }
            const response = await fetch(url)
            if (response.ok) {
                const stat = await response.json();
                setData(stat);
            }
            setPageLoading(false);
        }

        fetchStat();

    }, [search])

    function formatNumber(number) {
        return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
    }


    const downloadStat = async () => {
        setPageLoading(true);
        const response = await fetch('/api/stat_xlsx');
        if (response.ok) {
            const data = await response.blob();
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = "fastpay_stat.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        setPageLoading(false);
    }


    console.log(data.length);
    return (
        <div className="stat">
            <div className='statHeader'>
                <h1>Статистика</h1>
                <div className='search'>
                    <input
                        type='text'
                        placeholder='Поиск по токену офиса'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button onClick={downloadStat}>Скачать</button>
            </div>
            <div className='stats'>
                {Object.keys(data).length > 0 && Object.keys(data).map((office) =>
                    <div className='statOffice'>
                        <div className='statOfficeName'>
                            <h3>{data[office].name}</h3>
                            <p>{office}</p>
                        </div>
                        <div className='statOfficeData'>
                            <div className="statOfficeDataBlock">
                                <h4>Баланс</h4>
                                <p>{formatNumber(data[office].balance)} RUB</p>
                            </div>
                            <div className="statOfficeDataBlock">
                                <h4>Прибыль</h4>
                                <p>{formatNumber(data[office].profit)} RUB</p>
                            </div>
                            <div className="statOfficeDataBlock">
                                <h4>Ставка</h4>
                                <p>{data[office].rate}%</p>
                            </div>
                        </div>
                        <div className='statOfficeDetail'>
                            <div className='statOfficeCards'>
                                <h4>Реквизиты</h4>
                                <div className='statOfficeRow'>
                                    <div className='statOfficeBlock'>
                                        <h5>Активно</h5>
                                        <p>{data[office].cards.active}</p>
                                    </div>
                                    <div className='statOfficeBlock'>
                                        <h5>Все</h5>
                                        <p>{data[office].cards.count}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='statOfficeCards'>
                                <h4>Устройства</h4>
                                <div className='statOfficeRow'>
                                    <div className='statOfficeBlock'>
                                        <h5>Активно</h5>
                                        <p>{data[office].device.active}</p>
                                    </div>
                                    <div className='statOfficeBlock'>
                                        <h5>Все</h5>
                                        <p>{data[office].device.count}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='statOfficeDetail'>
                            <div className='statOfficePayment'>
                                {data[office].payments.count > 0 ? (
                                    <h4>Сделки - {parseFloat(data[office].payments.accepted / data[office].payments.count * 100).toFixed(0)}%</h4>
                                ) : (
                                    <h4>Сделки - 0%</h4>
                                )}
                                <div className='statOfficeRow'>
                                    <div className='statOfficeBlock'>
                                        <h5>Активно</h5>
                                        <p>{data[office].payments.active}</p>
                                    </div>
                                    <div className='statOfficeBlock'>
                                        <h5>Отмененные</h5>
                                        <p>{data[office].payments.cancel}</p>
                                    </div>
                                    <div className='statOfficeBlock'>
                                        <h5>Подтвержденные</h5>
                                        <p>{data[office].payments.accepted}</p>
                                    </div>
                                    <div className='statOfficeBlock'>
                                        <h5>Все</h5>
                                        <p>{data[office].payments.count}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}