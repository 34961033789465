import Transaction from "./components/Table/Transaction/Transaction";
import Sum from "./components/Table/Sum/Sum"
import Status from "./components/Table/Status/Status"
import Address from "./components/Table/Address/Address";
import BankCard from "./components/Table/BankCard/BankCard";
import DeviceComponent from "./components/Table/DeviceComponent/DeviceComponent";
import Limit from "./components/Table/Limit/Limit";
import Balance from "./components/Table/Balance/Balance";
import SuccessfulDeals from "./components/Table/SuccessfulDeals/SuccessfulDeals";
import NameRequisite from "./components/Table/NameRequisite/NameRequisite";
import MessageDate from "./components/Table/Message/MessageDate";
import MessageFrom from "./components/Table/Message/MessageFrom";
import MessageText from "./components/Table/Message/MessageText";
import DeviceState from "./components/DeviceState/DeviceState";
import DeleteButton from "./components/Table/DeleteButton/DeleteButton";

export function formatNumber(number) {
    return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
}

function formatDateFull(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('ru-RU', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(',', ' в');
    return formattedDate;
}

let d = new Date()
export let time = [d.getHours(), d.getMinutes(), d.getSeconds()].map(function (x) {
    return x < 10 ? "0" + x : x
}).join(":")

export const MIN_DATE = new Date('2024-01-01'); // Установите минимально возможную дату
export const MAX_DATE = new Date(); // Текущая дата

export const formatDate = (date) => {
    if (!date) {
        return '';
    }
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};


const transactionsData = {
    finance: [],
    deals: [],
    dispute: [],
    requisite: [],
    device: [],
    openDispute: [],
    // Добавьте сколько угодно транзакций
};

// const statusData = {
//     finance: [
//         {
//             backgroundColor: 'rgba(165, 224, 131, 0.501)',
//             color: 'rgb(19 91 0)',
//             text: 'Завершен'
//         },
//         {
//             backgroundColor: '#ffa1002b',
//             color: '#ffa100',
//             text: 'В процессе'
//         }
//     ],
//     requisite: [
//         {
//             backgroundColor: 'rgba(165, 224, 131, 0.501)',
//             color: 'rgb(19 91 0)',
//             text: 'Активно'
//         },
//         {
//             backgroundColor: 'rgba(165, 224, 131, 0.501)',
//             color: 'rgb(19 91 0)',
//             text: 'Приостановлено'
//         },
//         {
//             backgroundColor: '#ffa1002b',
//             color: '#ffa100',
//             text: 'Заблокировано'
//         }
//     ],
//     device: [
//         {
//             backgroundColor: 'rgba(165, 224, 131, 0.501)',
//             color: 'rgb(19 91 0)',
//             text: 'Активно'
//         },
//         {
//             backgroundColor: 'rgba(165, 224, 131, 0.501)',
//             color: 'rgb(19 91 0)',
//             text: 'Не активно'
//         },
//         {
//             backgroundColor: '#ffa1002b',
//             color: '#ffa100',
//             text: 'Не подключено'
//         }
//     ],
// };

const formatDateDevice = (dateString) => {
    const date = new Date('2024-09-03T07:31:25.014Z');
    const formattedDate = date.toLocaleString('ru-RU', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).replace(',', ' в');
    return formattedDate;
};

export const generateRows = (data, type) => {
    switch (type) {
        case 'finance':
            return data.map((transaction, index) => ({
                components: [index, [
                    <Transaction
                        key={`transaction-${index}`}
                        eventTime={transaction.eventTime}
                        icon={
                            transaction.transactionState ? 'simple-line-icons:plus' : 'simple-line-icons:minus'
                        }
                        style={{
                            color: transaction.transactionState ? '#67c44e' : 'red',
                            backgroundColor: transaction.transactionState ? 'rgba(165, 224, 131, 0.501)' : 'rgba(255, 0, 0, 0.17)'
                        }}
                        text={
                            transaction.transactionState ? 'Пополнение депозита' : 'Вывод средств'
                        }
                    />,
                    <Sum key={`sum-${index}`} transactionSum={transaction.transactionSum} />,
                    <Status key={`status-${index}`} status={transaction.transactionStatus} type={'finance'} />,
                    <Address key={`address-${index}`} transactionAddress={transaction.transactionAddress} />
                ]]
            }));

        case 'deals':
            return data.map((transaction, index) => ({
                components: [transaction.transactionId, [
                    <Transaction
                        key={index}
                        eventTime={transaction.eventTime}
                        icon={'iconoir:coins-swap'}
                        style={{
                            color: transaction.transactionState === null ? 'grey' : transaction.transactionState === true ? '#67c44e' : 'red',
                            backgroundColor: transaction.transactionState === null ? '#efefef' : transaction.transactionState === true ? 'rgba(165, 224, 131, 0.501)' : 'rgba(255, 0, 0, 0.17)',
                        }}
                        text={`${transaction.transactionDealId} ${transaction.transactionState === null 
                            ? 'Платеж выполняется' 
                            : transaction.transactionState 
                            ? 'Завершенная сделка' 
                            : 'Платеж отклонен'}`}
                    />,
                    <Sum
                        key={`${index}_sum`}
                        transactionSum={transaction.transactionSum}
                        transactionSumInRub={transaction.transactionSumIsRub}
                    />,
                    <BankCard
                        key={`${index}_card`}
                        bankCard={transaction.transactionBankCard}
                        bank={transaction.transactionBankName}
                    />,
                    <DeviceComponent
                        key={`${index}_device`}
                        name={transaction.transactionOwnerName}
                        uniqDevice={transaction.transactionUniqDevice}
                    />
                ], transaction.transactionState]
            }));

        case 'dispute':
            return data.map((transaction, index) => ({
                components: [transaction.transactionId, [
                    <Transaction
                        key={index}
                        eventTime={transaction.eventTime}
                        icon={
                            'icon-park-outline:database-time'
                        }
                        style={{
                            color: !transaction.transactionState ? 'grey' : transaction.transactionReason === null ? '#67c44e' : 'red',
                            backgroundColor: !transaction.transactionState ? '#efefef' : transaction.transactionReason === null ? 'rgba(165, 224, 131, 0.501)' : 'rgba(255, 0, 0, 0.17)',
                        }}
                        text={
                            !transaction.transactionState ? 'Открытый спор' : transaction.transactionReason === null ? 'Спор принят' : 'Спор не принят'
                        }
                    />,
                    <Sum
                        key={`${index}_sum`}
                        transactionSum={transaction.transactionSum}
                        transactionSumInRub={transaction.transactionSumIsRub}
                    />,
                    <BankCard
                        key={`${index}_card`}
                        bankCard={transaction.transactionBankCard}
                        bank={transaction.transactionBankName}
                    />,
                    <DeviceComponent
                        key={`${index}_device`}
                        name={transaction.transactionOwnerName}
                        uniqDevice={transaction.transactionUniqDevice}
                    />
                ], transaction.transactionState]
            }));

        case 'requisite':
            return data.map((transaction, index) => ({
                components: [transaction.transactionId, [
                    <NameRequisite
                        key={`${index}_name`}
                        fullName={transaction.transactionOwnerName}
                        nickname={transaction.transactionNickName}
                    />,
                    <BankCard
                        key={`${index}_card`}
                        bankCard={transaction.transactionBankCard}
                        bank={transaction.transactionBankName}
                    />,
                    <Status
                        key={`${index}_status`}
                        status={transaction.transactionStatus}
                        type={'requisite'}
                    />,
                    // <Balance
                    //     key={`${index}_balance`}
                    //     balance={transaction.transactionBalance}
                    // />,
                    <Limit
                        key={`${index}_limit`}
                        currentSum={transaction.transactionCurrentSum}
                        limit={transaction.transactionLimit}
                    />,
                    <SuccessfulDeals
                        key={`${index}_success`}
                        successful={transaction.transactionSuccessfullDeals}
                        allDeals={transaction.transactionAllDeals}
                    />,
                    <DeleteButton
                        key={`${index}_delete`}
                        id={transaction.transactionId}
                        status={transaction.transactionStatus}
                        setDelete={transaction.transactionSetDelete}
                    />
                ]]
            }));

        case 'device':
            console.log(data);
            return data.map((transaction, index) => ({
                components: [transaction.transactionId, [
                    <NameRequisite
                        key={`${index}_nameDevice`}
                        fullName={transaction.transactionNickName}
                        nickname={transaction.transactionUniqName}
                    />,
                    <Status
                        key={`${index}_statusDevice`}
                        status={transaction.transactionStatus}
                        type={'device'}
                    />,
                    transaction.transactionLastActive === null ? 'Не установлено' : formatDateFull(transaction.transactionLastActive),
                    transaction.transactionCountRequisite,
                    <DeviceState
                        key={`${index}_stateDevice`}
                        battery={transaction.transactionStateDevice}
                    />
                ]]
            }));

        case 'message':
            return data.map((transaction, index) => ({
                components: [index, [
                    <MessageDate
                        key={`${index}_date`}
                        eventTime={transaction.date}
                    />,
                    <MessageFrom
                        key={`${index}_from`}
                        from={transaction.from}
                    />,
                    <MessageText
                        key={`${index}_text`}
                        text={transaction.text}
                    />
                ]]
            }));

        default:
            return [];
    }
};


const data = {
    cards: {
        transactionCountAll: {
            icon: 'simple-line-icons:check',
            count: null,
            nameCard: 'Всего сделок',
        },
        transactionCountFinish: {
            icon: 'mdi:file-document-check-outline',
            count: null,
            nameCard: 'Завершенных сделок',
        },
        sum: {
            icon: 'mdi:file-document-check',
            count: null,
            currency: 'RUB',
            nameCard: 'Сумма завершенных сделок',
        },
        transactionCountInProcess: {
            icon: 'ci:arrow-reload-02',
            count: null,
            nameCard: 'Сделок в процессе',
        },
        activeDevices: {
            icon: 'tabler:device-mobile',
            count: null,
            nameCard: 'Активные устройства',
            styleCard: { borderColor: '#ffa100' },
        },
        balance: {
            icon: 'ion:card-outline',
            count: null,
            currency: 'RUB',
            nameCard: 'Баланс депозита',
        },
        // profit: {
        //     icon: 'ion:card-outline',
        //     count: null,
        //     currency: 'USDT',
        //     nameCard: 'Прибыль за период',
        // },
        // transactionsConfirmed: {
        //     icon: 'ion:card-outline',
        //     count: null,
        //     currency: 'USDT',
        //     nameCard: 'Подтвержденные сделки',
        // },
        timeProcessDispute: {
            icon: 'ion:time-outline',
            count: time,
            nameCard: 'Время обработки сделки',
            styleCard: { borderColor: 'red' },
            styleIcon: { backgroundColor: '#ff00002b', color: 'red' },
        }
    },
    links: {
        dashboard: {
            icon: 'ri:dashboard-fill',
            nameLink: 'Дашборд',
            link: '/'
        },
        finance: {
            icon: 'fa6-solid:coins',
            nameLink: 'Финансы',
            link: '/finance'
        },
        deals: {
            icon: 'iconoir:coins-swap',
            nameLink: 'Сделки',
            link: '/deals'
        },
        dispute: {
            icon: 'icon-park-outline:database-time',
            nameLink: 'Споры',
            link: '/dispute'
        },
        requisite: {
            icon: 'ion:card-outline',
            nameLink: 'Реквизиты',
            link: '/requisite'
        },
        device: {
            icon: 'tabler:device-mobile',
            nameLink: 'Устройства',
            link: '/device'
        },
    },
    tables: {
        finance: {
            headers: ['Транзакция', 'Сумма', 'Статус', 'Адрес транзакции'],
            // rows: generateRows(transactionsData.finance, 'finance'),
        },
        deals: {
            searchInput: ['Поиск по ID'],
            headers: ['Сделка', 'Сумма', 'Карта', 'Устройство'],
            // rows: generateRows(transactionsData.deals, 'deals'),
        },
        dispute: {
            searchInput: ['Поиск по номеру карты'],
            headers: ['Статус спора', 'Сумма', 'Карта', 'Устройство'],


        },
        requisite: {
            searchInput: ['Поиск по номеру карты'],
            headers: ['ФИО', 'Карта', 'Статус', 'Суточный лимит', 'Сделки', ' '],
            // rows: generateRows(transactionsData.requisite, 'requisite'),

        },
        device: {
            searchInput: ['Поиск'],
            headers: ['Устройство', 'Статус', 'Последняя активность', 'Реквизиты', 'Состояние'],
            // rows: generateRows(transactionsData.device, 'device'),

        },
        message: {
            searchInput: [],
            headers: ['Дата', 'От кого', 'Текст'],
        },
        openDispute: {
            rows: transactionsData.openDispute
        }

    },
    exchange: {
        RUB: 90,
        currency: '₽'
    }
};

export default data;
