import React, { useState, useEffect } from 'react';
import DateComponent from '../../../components/DateComponent/DateComponent';
import Table from '../../../components/Table/Table';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import './module.css';
import { formatDate, MAX_DATE, MIN_DATE, generateRows } from '../../../Data';

export default function Deals({ data, setPageLoading }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [autoUpdate, setAutoUpdate] = useState(true);
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'deals');

    useEffect(() => {
        const fetchData = async () => {
            if ((startDate && !endDate) || (!startDate && endDate)) {
                console.warn("Даты не выбраны. Запрос не будет выполнен.");
                return;
            }

            setPageLoading(true);
            try {
                let url = `/api/payments?office_id=${officeId}&date_st=${formatDate(startDate)}&date_en=${formatDate(endDate)}`;
                if (searchData) {
                    url = `/api/payment/${searchData}`;
                }

                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    console.log(dynamicData);

                    let formattedData = [];

                    if (Array.isArray(dynamicData.payments)) {

                        // Обработка данных как массива объектов
                        formattedData = dynamicData.payments.map(item => ({
                            transactionId: item.payment.id,
                            transactionDealId: item.payment.deal_id,
                            eventTime: item.date.create,
                            transactionState: item.payment.status === "AC" ? true : item.payment.status === "CL" ? false : null,
                            transactionSum: item.payment.amount.usdt,
                            transactionSumIsRub: item.payment.amount.rub,
                            transactionBankCard: item.payment.card.number,
                            transactionBankName: item.payment.type,
                            transactionOwnerName: item.payment.device.owner,
                            transactionUniqDevice: item.payment.device.id
                        }));
                    } else if (dynamicData.id) {
                        // Обработка данных как объекта
                        formattedData = [
                            {
                                transactionId: dynamicData.id,
                                transactionDealId: dynamicData.deal_id,
                                eventTime: dynamicData.create,
                                transactionState: dynamicData.status === "AC" ? true : dynamicData.status === "CL" ? false : null,
                                transactionSum: dynamicData.amount_usdt,
                                transactionSumIsRub: dynamicData.amount,
                                transactionBankCard: dynamicData.card.number,
                                transactionBankName: dynamicData.card.bank,
                                transactionOwnerName: dynamicData.card.name,
                                transactionUniqDevice: dynamicData.device.token
                            }
                        ];

                    } else {
                        console.error('Непредвиденный формат данных:', dynamicData);
                    }

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
        if (autoUpdate) {
            const interval = setInterval(fetchData, 7000);
            return () => clearInterval(interval);
        }
    }, [autoUpdate, officeId, startDate, endDate, searchData, data]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSelectAll = () => {
        setStartDate(MIN_DATE);
        setEndDate(MAX_DATE);
    };

    const handleFilterChange = (searchTerm) => {
        setSearchData(searchTerm.trim());
    };

    return (
        <div className='deals'>
            <div className="header">
                <h1>Сделки</h1>
            </div>
            <div className='headerData'>
                <SearchFilter
                    data={data.tables.deals.searchInput}
                    onFilterChange={handleFilterChange}
                    showSumFilter={true}
                />
                <div className="autoUpdate">
                    <label htmlFor="autoReq">Автообновление</label>
                    <input
                        id="autoReq"
                        type="checkbox"
                        checked={autoUpdate}
                        onChange={(e) => setAutoUpdate(e.target.checked)}
                    />
                </div>
                <DateComponent
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                    handleSelectAll={handleSelectAll}
                />
            </div>
            <Table
                headers={data.tables.deals.headers}
                rows={genRows.map(row => row.components)}
                rowHover={'rowHover'}
            />
        </div>
    );
}
