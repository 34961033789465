import React, { useState } from "react";
import "./module.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import Status from "../../../../../components/Table/Status/Status";
import SuccessfulDeals from "../../../../../components/Table/SuccessfulDeals/SuccessfulDeals";
import Verification from "../../../../../components/ModalVerification/Verification";
import Popup from 'reactjs-popup';

export default function OpenBillsOrCards({ data, fetchData }) {
    const [showModal, setShowModal] = useState(false);
    const [showModalNew, setShowModalNew] = useState(false);
    const [dopValue, setDopValue] = useState(null);
    const [verif, setVerif] = useState(false);
    const [dop, setDop] = useState(true);
    const [verifCard, setVerifCard] = useState({});

    function formatNumber(number) {
        return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
    }

    const openVerification = (card) => {
        if (card.main) {
            setDop(false);
            setVerifCard(data.card);
        } else {
            setVerifCard(card);
        }

        setShowModal(true);
    }

    const closeVerification = () => {
        setDop(true);
        setShowModal(false);
    }

    const openNewDetail = (info) => {
        if (info === "card_number") {
            setDopValue("card");
        } else {
            setDopValue("payment");
        }
        setShowModalNew(true);
    }

    const closeNewDetail = () => {
        setShowModalNew(false);
        setDopValue(null);
    }

    return (
        <div className="billsOrCardsContainer">
            <div className="HeaderName">
                <b>Счета/Карты</b>
                <div className="addNewHeader">
                    {data.card?.additional_1 && (
                        <button
                            onClick={() => openNewDetail(data.card.additional_1)}
                        >Добавить {data.card.additional_1 === "card_number" ? "карту" : "cчет"}</button>
                    )}
                    {data.card?.additional_2 && (
                        <button
                            onClick={() => openNewDetail(data.card.additional_2)}
                        >Добавить {data.card.additional_2 === "card_number" ? "карту" : "cчет"}</button>
                    )}
                </div>
            </div>
            {data.additional.map(card => (
                <div className="tableData">
                    <div className="numberTableData">
                        <p>Номер</p>
                        <div className="numberBodyData">
                            <Icon icon="f7:doc-text-fill" />
                            {card.number}
                        </div>
                    </div>
                    <div className="statusTableData">
                        <p>Статус</p>
                        {card.status ?
                            data.card.condition ? (
                                <Status status={'Активно'} type={'requisite'} />
                            ) : (
                                <Status status={'Приостановлено'} type={'requisite'} />
                            ) : (
                                <div className="verifAction" onClick={() => openVerification(card)}>
                                    <Status status={'Не подключено'} type={'requisite'} />
                                </div>
                            )
                        }
                    </div>
                    <div className="turnoverTableData">
                        <p>Баланс</p>
                        {formatNumber(card.balance)} RUB
                    </div>
                    <div className="turnoverTableData">
                        <p>Оборот</p>
                        {formatNumber(card.volume)} RUB
                    </div>
                    <div className="conversionTableData">
                        <p>Конверсия</p>
                        <SuccessfulDeals
                            successful={card.deals_approve}
                            allDeals={card.deals}
                        />
                    </div>
                </div>

            ))}
            <Popup open={showModal} onClose={closeVerification}>
                <Verification
                    card={verifCard}
                    amount={verifCard?.verif_amount}
                    close={closeVerification}
                    setVerif={setVerif}
                    dop={dop}
                />
            </Popup>

            <Popup open={showModalNew} onClose={closeNewDetail}>
                <NewDetail
                    type={dopValue}
                    detailId={data.card.id}
                    close={closeNewDetail}
                    updateWindow={fetchData}
                />
            </Popup>
        </div>
    );
}


function NewDetail({ type, detailId, close, updateWindow }) {

    const [newValue, setNewValue] = useState(null);
    const [error, setError] = useState(null)

    const handleCardChange = (field, value) => {

        let formattedValue = value;

        if (field === 'cardNumber') {
            formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        } else if (field == 'paymentNumber') {
            formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
        }

        setNewValue(formattedValue);
    };

    const validateCardNumber = (number, length) => {
        const cleanedNumber = number.replace(/\s/g, '');
        return cleanedNumber.length === length && /^\d+$/.test(cleanedNumber);
    };

    const handleSubmit = () => {

        if (type == "card") {
            if (!validateCardNumber(newValue, 16)) { setError('Введите корректный номер карты') };
        } else {
            if (!validateCardNumber(newValue, 20)) { setError('Введите корректный номер счета') };
        }

        if (!error) {
            fetchData();
        }
    };

    const fetchData = async () => {
        const body = { [type]: newValue }
        const response = await fetch(`/api/detail/${detailId}/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        if (response.status == 200) {
            updateWindow();
            close();
        } else {
            setError('Ошибка при добавлении')
        }
    };

    return (
        <div className="modal">
            <div className="modalHeader">Добавление новых реквизитов</div>
            <div className="content">
                <div className="inputData">
                    {type === "card" && (
                        <div className='inputData'>
                            <label>Дополнительная карта</label>
                            <div className="inputDataCard">
                                <Icon icon='ion:card-outline' width="20px" height="20px" />
                                <input
                                    type="text"
                                    value={newValue}
                                    onChange={(e) => handleCardChange('cardNumber', e.target.value)}
                                    onFocus={() => setError(null)}
                                    placeholder="Введите карту"
                                    maxLength="19"
                                />
                            </div>
                            {error && <span style={{ color: 'red' }}>{error}</span>}
                        </div>
                    )}
                    {type === "payment" && (
                        <div className='inputData'>
                            <label>Дополнительный счет</label>
                            <div className="inputDataCard">
                                <Icon icon='ion:card-outline' width="20px" height="20px" />
                                <input
                                    type="text"
                                    value={newValue}
                                    onChange={(e) => handleCardChange('paymentNumber', e.target.value)}
                                    onFocus={() => setError(null)}
                                    placeholder="Введите номер счета"
                                    maxLength="24"
                                />
                            </div>
                            {error && <span style={{ color: 'red' }}>{error}</span>}
                        </div>
                    )}
                </div>
            </div>
            <div className="modalActions">
                <button className="addReqBtn" onClick={handleSubmit}>Добавить</button>
            </div>
        </div>
    );
}