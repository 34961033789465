import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import "./module.css";
import OpenProfit from "./OpenProfit/OpenProfit";
import { formatNumber } from "../../../../Data";
import OpenRequisite from "../../OpenComponents/OpenRequisite/OpenRequisite";
import OpenDevice from "../../OpenComponents/OpenDevice/OpenDevice";
import Transaction from "../../../../components/Table/Transaction/Transaction";
// import { Icon } from "@iconify/react";



export default function OpenDeals({ setPageLoading }) {
    const { id } = useParams();
    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            setPageLoading(true);
            try {

                const response = await fetch(`/api/payment/${id}`);
                if (response.ok) {
                    const dynamicData = await response.json();
                    setFilteredData(dynamicData);
                    console.log(dynamicData)

                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
    }, [id]);

    return (

        <div className='openDeals'>
            {filteredData && (
                <>
                    <div className='headerDataDeals'>
                        <Transaction
                            text={filteredData.status === "AC" ? 'Завершенная сделка' : filteredData.status === "CL" ? 'Платеж отклонен' : 'Платеж выполняется'}
                            icon='iconoir:coins-swap'
                            style={{
                                color: filteredData.status === "AC" ? '#67c44e' : filteredData.status === "CL" ? 'red' : 'grey',
                                backgroundColor: filteredData.status === "AC" ? 'rgba(165, 224, 131, 0.501)' : filteredData.status === "CL" ? 'rgba(255, 0, 0, 0.17)' : '#efefef'
                            }}
                            eventTime={`ID сделки: ${id}`}
                        />
                        <div className='pricesData'>
                            {/* <b>
                            <p className="priceUsdt">
                                {formatNumber(filteredData.amount_usdt)} USDT
                            </p>
                        </b> */}
                            <b>
                                <p className="priceRub">
                                    {formatNumber(filteredData.amount)} RUB
                                </p>
                            </b>
                        </div>
                    </div>
                    <hr />
                    <div className="dataDeals">
                        <OpenProfit data={filteredData} />
                        <OpenRequisite
                            props={filteredData}
                        />
                        <OpenDevice
                            props={filteredData}
                        />
                    </div>
                </>
            )}
        </div>

    );
}